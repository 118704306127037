<template>
  <v-app style="width:100%">
    <v-container fluid>
      <v-checkbox color="blue" @change="students=[]" v-model="checkbox" label="Single student (use reg no or names)"></v-checkbox>
      <v-row>
        <v-col
          cols="4"
          sm="12"
          md="4"
          v-show="checkbox==false"
        >
          <v-select
            :items="classes"
            v-model="classe"
            label="Class"
            outlined
            dense
            @change="getClassStudent"
          ></v-select>
        </v-col>
        <v-col cols="4" sm="12" md="4" v-show="checkbox">
          <vx-select
            v-model="searchedStudent"
            v-on:search="getStudents($event)"
            :options="searchedStudents"
            style="max-width: 100%;margin-bottom: 10px !important;"
            v-on:input="appendStudentToList(searchedStudent)"
          ></vx-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="7"
          sm="12"
          md="7"
        >
          <v-data-table
            :headers="headers"
            :items="students"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item="{ item }">
              <tr :style="item.photo==''?'color:red':'black'">
                <td>
                  <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
                    {{students.map(function(x) {return x.code; }).indexOf(item.code)+1}}
                  </v-chip>
                </td>
                <td>{{ item.label }}</td>
                <td>
                  <b v-show="item.photo==''">No photo</b>
                  <img :src="axios.defaults.baseURL+'assets/uploads/passport/'+item.photo" style="width: 50px" v-show="item.photo!=''">
                </td>
                <td>{{ item.reg_no }}</td>
                <td>
                  <v-btn x-small color="error" @click="deleteStudent(item.id,item.names,item)">
                    Remove
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <vue-confirm-dialog></vue-confirm-dialog>
        </v-col>
        <v-col
          cols="5"
          sm="12"
          md="5"
        >
          <v-card
            elevation="2"
            shaped
            tile
            style="padding: 30px"
          >
            <div class="text-center">
              <form method="POST" :action="axios.defaults.baseURL+'generateStudentCard'" target="_blank">
                <input type="hidden" name="students" v-model="studentsForm">
                <v-btn
                  :style="studentWithPhoto==0 || disabled?'background-color: #00a4e4 !important;color:#ffffff':''"
                  color="primary"
                  dark
                  :disabled="studentWithPhoto==0 || disabled"
                  type="submit"
                >
                  {{btnTxt}}
                  <v-chip x-small>{{studentWithPhoto}}</v-chip>
                </v-btn>
              </form>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'StudentCard',
  data () {
    return {
      classe: '',
      classes: [],
      loading: false,
      checkbox: false,
      headers: [
        { text: '#', value: 'code' },
        { text: 'Names', value: 'label' },
        { text: 'Photo', value: 'photo' },
        { text: 'Registration', value: 'reg_no' },
        { text: 'Actions', value: 'actions' },
      ],
      students: [],
      student: '',
      header: '',
      logger: [],
      searchedStudents: [],
      searchedStudent: '',
      btnTxt: 'Generate card',
      disabled: false,
      studentsForm: '',
    }
  },
  computed: {
    studentWithPhoto () {
      let i = 0
      this.students.map(student => {
        if (student.photo != '') {
          i++
        }
      })
      return i
    },
  },
  mounted () {
    this.getClasses()
  },
  created () {
    if (localStorage.getItem('logged_user')) {
      this.logger = JSON.parse(localStorage.getItem('logged_user'))
    }
    this.header = this.logger.accessToken
  },
  methods: {
    appendStudentToList () {
      if (this.students.indexOf(this.searchedStudent) === -1) {
        this.students.push(this.searchedStudent)
        this.searchedStudent = ''
      } else {
        console.log(this.students.indexOf(this.searchedStudent))
      }
    },
    async getStudents (prefix) {
      if (prefix.length === 3) {
        this.searchedStudents = []
        try {
          const res = await this.axios({
            url: 'getSearchedStudent',
            method: 'post',
            data: {
              prefix: prefix,
            },
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.searchedStudents = res.data
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    deleteStudent (id, title, item) {
      this.$confirm(
        {
          message: 'Delete ' + ' ' + title + ' ' + 'from list ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              this.students.splice(this.students.indexOf(item), 1)
            }
          },
        },
      )
    },
    async getClassStudent () {
      this.students = []
      try {
        const res = await this.axios({
          url: 'getClassStudents/' + this.classe,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.students = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async getClasses () {
      try {
        const res = await this.axios({
          url: 'getClasse',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.classes = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    generateCard () {
      const students = this.students.map(student => {
        return student.code
      })
      window.open(this.axios.defaults.baseURL + '/generateStudentCard/' + students, '_blank')
    },
  },
  watch: {
    students: function (val) {
      const students = val.map(student => {
        return student.code
      })
      this.studentsForm = students
    },
  },
}
</script>

<style scoped>

</style>
